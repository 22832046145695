import React from 'react'
import {useState, useEffect, useMemo} from 'react'
import TabShopv1 from './dataTablev1/TabShopv1'
import TabCashdeskv1 from './dataTablev1/TabCashdeskv1'
import {useLazyGetShopsQuery} from "../../services/modules/store";
import {useLazyGetCashdesksQuery} from "../../services/modules/cashdesk";
import {launcherContext} from './qrGenerationContext'

function BroadSearch() {
  const [getCashdesks, cashdesks] = useLazyGetCashdesksQuery();
  const [getShops, shops] = useLazyGetShopsQuery();
  const [errGetCD, setCodeErrGetCD] = useState('init');
  const [errGetStore, setCodeErrGetStore] = useState('');
  const [shopsInfo, setShopsInfo] = useState('');
  const [shopID, setShopID] = useState('');
  const [shopName, setShopName] = useState('');
  const [cashDesksInfo, setCashDesksInfo] = useState('');
  const [editShop, setEditShop] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState('');
  // context
  const [fetchStore, setFetchStore] = useState(true);

  const cleanShop = () => {setShopID('')}

  useEffect(() => {
    if (shopID!=='') {
      const fetchData = async () => {
        const rep = await getCashdesks({ 
          "data": {
            "type": "CASHDESK",
            "action": "SEARCH",
              "attributes": {
                "tsid":shopID
              }
        },
        });
        if (rep.isError===true) {
          setCodeErrGetCD(rep.error.data.code);
        } else { 
          setCashDesksInfo(rep);
          setCodeErrGetCD(null);
        };
      };

      fetchData()
      .catch(console.error);}
      },[shopID]);

  useEffect(() => {
      const fetchData = async () => {
        const rep = await getShops({
          "data": {
            "type": "STORE",
            "action":"LIST",
            "attributes":{}
            /*"attributes": {
              "userEmail": payload
            }*/ // attributes only if Super admin to select one B2B space id to look at
          }});
        if (rep.isError===true) {
          setCodeErrGetStore(rep.error.data.code);
        } else { 
          setShopsInfo(rep);
          setCodeErrGetStore(null);
        }
        setFetchStore(false)
      };

      console.log('fetchStorefetchStorev', fetchStore)
      if (fetchStore) {
        console.log('oFetch de nouveau', fetchStore)
        fetchData().catch(console.error);
      }

    },[fetchStore, getShops]);

  const context = {
    fetchStore,
    onFetchStore: (value) => {
      console.log('onFetchStoreonFetchStore', value)
      setFetchStore(value)
    }
  };

  return (
    <launcherContext value={context} className="w-full">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          {shopID!=='' && editShop===false ?
            <button
              onClick={()=>cleanShop()}
              className='w-full text-electric hover:text-white border border-electric hover:bg-electric0 focus:ring-4 focus:outline-none focus:ring-electric font-medium rounded-lg text-sm px-5 py-1 text-center mr-2 mb-2'>
              Clear selection</button>
          :null}
        </div>
        <div className="flex justify-around">
          <div className="px-2 w-1/2">
          {!editShop ? <p className="text-center">SHOPS</p>:null}
            <TabShopv1 shops={shopsInfo} fShopID={(value) => setShopID(value)} fEditShop={(value) => setEditShop(value)} fTriggerUpdate={(value)=>setTriggerUpdate(value)} err={errGetStore} fShopName={(value) =>setShopName()}/>
          </div>
          {!editShop ?
            <div className='px-2 w-1/2'>
              <p className="text-center">CAISSES</p>
              <TabCashdeskv1 cashdesks={cashDesksInfo} shopID={shopID} err={errGetCD} shop={shopName}/>
            </div> : null}
        </div>
      </div>
    </launcherContext>
  )
}

export default BroadSearch