import React, {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import EditBrand from '../editModals/EditBrand';

function TabBrand(props) {

  const {brands, fBrandID, closeModal} = props;
  const [query, setQuery] = useState('');
  const [modal, setModal] = useState(closeModal);
  const [brandID, setBrandID] = useState('');

  const handleChange = event => {
    setQuery(event.target.value);
  };

  function editBrand (brand) {
    setModal(true);
    setBrandID(brand);
  }

  if (brands==="") {

    return (

      <div>
          <div>'Loading...'</div>
      </div>

      )} 
  else if (brandID!==null && brandID!=='' && modal===true) {

    return(<EditBrand brandID={brandID} closeModal={(value) => setModal(value)}/>)} 

  else {
        const data=brands.data.data;

        return (
          <div>
            <div className="relative shadow-md sm:rounded-lg my-1">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <FaSearch size={16} color="grey"/>
                </div>
                <input 
                type="text" 
                id="table-search" 
                className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                placeholder="Search for items"
                onChange={handleChange}
                value={query}/>
            </div>
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg py-1">
              <div className="h-96">
                {data.filter(item => item.attributes.title.toLowerCase().includes(query)).map((item, i) =>
                <div key={i} onClick={() => fBrandID(item.attributes.toid)} className="py-px">
                  <a href="#" className="block bg-white py-px border-t rounded-md hover:drop-shadow-lg">
                    <div className="px-4 py-1 flex justify-between">
                      <span className="text-sm font-semibold text-gray-900">{item.attributes.title}</span>
                      <span className="text-sm font-semibold text-gray-600">
                      <button 
                        onClick={()=>editBrand(item.attributes)}
                        className='text-[white] bg-sky-500 w-[69px] py-1 rounded-md font-medium text-md hover:drop-shadow-md'>
                          Edit</button>
                      </span>
                    </div>
                    <span className="text-sm text-gray-900 px-4 py-1">{item.attributes.name} {item.attributes.surname}</span>
                    <p className="px-4 py-1 text-sm text-gray-700">{item.attributes.address_address}</p>
                    <p className="px-4 py-1 text-sm text-gray-700">{item.attributes.siren}</p>
                  </a>
                </div>
                )}
              </div>
            </div>
          </div>

        )
      }
  }

export default TabBrand