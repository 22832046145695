import {useState, useEffect} from 'react';
import {useLazySignUpQuery} from "../services/modules/auth";
import {useNavigate} from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

function SignUp() {

    const navigate = useNavigate();
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVal,setPasswordVal] = useState('');
    const [code, setCode] = useState('');
    const [codeErrSignUp, setCodeErrSignUp] = useState(null);
    const [codeErrSignUpVal, setCodeErrSignUpVal] = useState(null);
    const [signUp, signUpR] = useLazySignUpQuery();
    const [hideVal, setHideVal] = useState(true);
    const [verrCode, setVerrCode] = useState(false);
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  
    const handleSubmitSignUp = async (event) => {
      setCodeErrSignUp(null);
      if (hideVal===true) {
        handleVal();
      };
      event.preventDefault();
      const rep = await signUp({
        "data": {
          "type": "AUTH",
          "action": "SIGNUP",
          "attributes": {
            "email": email,
            "password": password,
            "captcha": token
          }
        }
      });
      if (rep.isError===true) {
        setCodeErrSignUp(rep.error.data.code);
        setVerrCode(false);
        //console.log(rep.error.data.code);
      } else {
        setVerrCode(true);
      };
      setRefreshReCaptcha(r => !r);
    }

    const handleSubmitSignUpValidation = async (event) => {
      setCodeErrSignUpVal(null);
      event.preventDefault();
      const rep = await signUp({
        "data": {
          "type": "AUTH",
          "action": "SIGNUP_CONFIRM",
          "attributes": {
            "email": email,
            "confirmationCode": code,
            "captcha": token
          }
        }
      });
      if (rep.isError===true) {
        setCodeErrSignUpVal(rep.error.data.code);
      } else {
        navigate('/Cockpit');
      };
    }

    const handleVal  = () => {
      setHideVal(!hideVal);
    }

    useEffect(() => {
    }, [signUpR]);

    console.log(token);

  return (

    <div className="bg-gray-100 py-4 flex flex-col">
        <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
            <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
                <h1 className="mb-8 text-3xl text-center uppercase">Créer un compte</h1>
                <input 
                    type="text"
                    className="block border border-grey-light w-full p-3 rounded mb-4"
                    name="email"
                    placeholder="Email"
                    value={email}
                    disabled={verrCode}
                    onChange={event => setEmail(event.target.value)} />

                <input 
                    type="password"
                    className="block border border-grey-light w-full p-3 rounded mb-4"
                    name="password"
                    placeholder="Password"
                    value={password} 
                    disabled={verrCode}
                    onChange={event => setPassword(event.target.value)} />

                <input 
                    type="password"
                    className="block border border-grey-light w-full p-3 rounded mb-4"
                    name="passwordValidation"
                    placeholder="Password Validation"
                    value={passwordVal}
                    disabled={verrCode}
                    onChange={event => setPasswordVal(event.target.value)} />

                {password!==passwordVal ?
                <div>
                  <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p>Passwords are different!</p>
                  </div>
                  <GoogleReCaptcha         
                    onVerify={(token) => {setToken(token)}}
                    refreshReCaptcha={refreshReCaptcha}/>
                  <button
                      type="submit"
                      disabled={true}
                      className="w-full text-center py-3 rounded bg-white text-gray-100 hover:bg-green-dark focus:outline-none my-1"
                      onClick={handleSubmitSignUp}
                      >Générer un code de validation</button>
                  </div>:
                  <div>
                <GoogleReCaptcha         
                    onVerify={(token) => {setToken(token)}}
                    refreshReCaptcha={refreshReCaptcha}/>
                <button
                    type="submit"
                    disabled={false}
                    className={`w-full text-center py-3 rounded bg-electric text-white hover:bg-green-dark focus:outline-none my-1`}
                    onClick={handleSubmitSignUp}
                    >Générer un code de validation</button>
                    </div>}


                {codeErrSignUp!=null ? 
                  <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                  <span className="font-medium">Info:</span> {codeErrSignUp}
                </div>:null}

                {!hideVal ? <p className='border-b border-gray-200 mt-4'></p>  :null}   

                {!hideVal ? <input type="text" 
                        className="block border border-grey-light w-full p-3 rounded mb-4 py-2 mt-4"
                        name="validation_code"
                        placeholder="Rentrer le code de validation"
                        value={code} 
                        onChange={event => setCode(event.target.value)}/>:null}
                {!hideVal ? 
                    <button
                        type="submit"
                        className={`w-full text-center py-3 rounded bg-electric text-white hover:bg-green-dark focus:outline-none my-1`}
                        onClick={handleSubmitSignUpValidation}
                        >Créer un compte</button>:null}

                {codeErrSignUpVal!=null ? 
                  <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                  <span className="font-medium">Info:</span> {codeErrSignUpVal}
                </div>:null}
                        
                <div className="text-center text-sm text-grey-dark mt-4">
                    By signing up, you agree to the 
                    <a className="no-underline border-b border-grey-dark text-grey-dark px-1" href="#">
                        Terms of Service
                    </a> and 
                    <a className="no-underline border-b border-grey-dark text-grey-dark px-1" href="#">
                        Privacy Policy
                    </a>
                </div>
            </div>
            <div className="text-grey-dark mt-6">
                Already have an account? 
                <a className="no-underline hover:underline border-b border-blue text-blue px-1" href="../SignIn/">
                    Log in
                </a>.
            </div>
        </div>
    </div>
  )
}

export default SignUp