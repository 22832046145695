import React , { useState, useEffect }  from 'react';
import {useSelector, useDispatch} from "react-redux";
import {IoCloseOutline, IoReorderThreeOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';


import {useLazyGetUsersQuery} from "../services/modules/access";

import User from './UserNavbar'
import logo from '../assets/img/logo-quipo-forest.png'

const Navbar = () => {

    const dispatch = useDispatch()
    const [getUsers, users] = useLazyGetUsersQuery();
    const [errGet, setCodeErrGet] = useState('');

    const [nav, setNav] = useState(true);

    const navigate = useNavigate();

    const navigateToSignUp = () => {
      navigate('/SignUp');
    };

    const navigateToSignIn = () => {
        navigate('/SignIn');
      };

    const handleNav = () => {
        setNav(!nav);
    };

    const path = window.location.pathname;

    const auth = useSelector((state) => state.user.haveSignIn);
    const userID = useSelector((state) => state.user.userB2bID);
    const userMail = useSelector((state) => state.user.userEmail?.userInfo);

    return (

        <nav className={`bg-darkness border-gray-200 px-2 sm:px-4 py-3`}>
            <div className='px-2 flex justify-between items-center w-full h-full'>
                    <a href="http://auth.occidev31.com:3000/" className="flex items-center">
                        <img className="object-contain h-16" src={logo} alt="Logo" />
                    </a>

                    <div onClick={handleNav} className='md:hidden mr-4'>
                        {!nav ? <IoCloseOutline size={40} color='white'/> : <IoReorderThreeOutline size={40} color='white'/>}
                    </div>

                    <div className='hidden md:flex md:flex-1 lg:w-0 justify-end'>
                        {auth ? <User  userID={userID} userEmail={userMail}/> : 
                            <div className='hidden md:flex pr-4'>
                            {path==="/SignIn" ? null:<button 
                            className={`text-forest hover:border-none hover:text-electric border border-forest hover:bg-forest focus:ring-4 focus:outline-none focus:ring-forest font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2`}
                            onClick={navigateToSignIn}>
                                Sign In
                            </button>}
                            {path==="/SignUp" ? null:<button 
                            className={`text-forest hover:border-none hover:text-electric border border-forest hover:bg-forest focus:ring-4 focus:outline-none focus:ring-forest font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2`}
                            onClick={navigateToSignUp}>
                                Sign Up
                            </button>}
                        </div>}
                    </div>
                        
                    <div className={!nav ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 dark:bg-gray-900 ease-in-out duration-300" : "fixed left-[-100%]"}>
                        <a href="http://auth.occidev31.com:3000/" className="flex items-center">
                            <img className="object-contain h-16" src={logo} alt="Logo" />
                        </a>

                            {auth ? 
                            <div className='flex flex-col my-10 px-14'>
                                <User user={userID} userEmail={userMail}/>
                            </div> : 
                            <div className='flex flex-col my-4 px-5'>
                                {path==="/signIn" ? null:<button className={`text-forest hover:border-none hover:text-electric border border-forest hover:bg-forest focus:ring-4 focus:outline-none focus:ring-forest font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2`} onClick={navigateToSignIn}>Sign In</button>}
                                {path==="/signUp" ? null:<button className={`text-forest hover:border-none hover:text-electric border border-forest hover:bg-forest focus:ring-4 focus:outline-none focus:ring-forest font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2`} onClick={navigateToSignUp}>Sign Up</button>}
                            </div>}

                </div>
            </div>
        </nav>
    )
}

export default Navbar