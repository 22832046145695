import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import {QRCodeSVG} from 'qrcode.react';

function DisplayQR() {

  let { cashdeskId } = useParams();
  const [data, setData] = useState("");

  cashdeskId=cashdeskId.substring(1);
  const wsuri = `wss://tl-frd-1.occiback.dev/id/${cashdeskId}`;
  const socket = new WebSocket(wsuri);

  socket.onmessage = function (event) {
    var data = JSON.parse(event.data);
    console.log(atob(data.data.attributes.data64));
    setData(data);
  };

  useEffect(() => {

  });
  if(data!==""){
    return (
      <div>
        <div className="container mx-auto px-4 sm:px-8">
          <div className="flex flex-col items-center">
            {cashdeskId}
          </div>
          <div className="flex justify-center">
            <div>
              <QRCodeSVG value={atob(data.data.attributes.data64)} fgColor="#5900FF" size="512" includeMargin="true" level="M"/>
            </div>
          </div>
        </div>
      </div>
    )
    } else {
      return(
      <div>
      <div className="container mx-auto px-4 sm:px-8">
        <div className="flex flex-col items-center">
          {cashdeskId}
        </div>
      </div>
    </div>)
    }
}

export default DisplayQR