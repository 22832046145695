import { api } from "../../api";
import editMessage from "./editMessage";
import getMessages from "./getMessages";
import deleteMessage from "./deleteMessage";

export const messageApi = api.injectEndpoints({
  endpoints: (build) => ({
    editMessage: editMessage(build),
    getMessages: getMessages(build),
    deleteMessages: deleteMessage(build)
  }),
  overrideExisting: false,
});

export const { useLazyEditMessageQuery, useLazyGetMessagesQuery, useLazyDeleteMessagesQuery } = messageApi;
