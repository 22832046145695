import { api } from "../../api";
import getShops from "./getShops";
import editShop from "./editShop";

export const storeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShops: getShops(build),
    editShop: editShop(build),
  }),
  overrideExisting: false,
});

export const { useLazyGetShopsQuery, useLazyEditShopQuery } = storeApi;
