import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import {Config} from "../config";
import {onChangeHaveSignIn, keepUser, btobID} from "../store/user";

const baseQuery = fetchBaseQuery({
  baseUrl: Config.API_URL,
  credentials: "include",
  prepareHeaders: (headers) => {
    //headers.set('x-cors-for', Config.header)
    return headers
  }
});

const baseQueryWithInterceptor = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.data.code === 'E_COOKIES_404') {
    const refreshResult = await baseQuery({
      url: '/auth', 
      method: 'LINK', 
      credentials: "include"
    }, api, extraOptions)
    if (refreshResult.error && refreshResult.error.data.code === 'E_COOKIES_404') {
      api.dispatch(onChangeHaveSignIn({haveSignIn: false}))
    } else {
      const results = await baseQuery(args, api, extraOptions)
      return results
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
});
