import { api } from "../../api";
import getBrands from "./getBrands";
import getOne from "./getOne";

export const ownerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOne: getOne(build),
    getBrands: getBrands(build),
  }),
  overrideExisting: false,
});

export const { useLazyGetOneQuery, useLazyGetBrandsQuery} = ownerApi;
