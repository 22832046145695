import { api } from "../../api";
import getGroups from "./getGroups";

export const groupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGroups: getGroups(build),
  }),
  overrideExisting: false,
});

export const {useLazyGetGroupsQuery} = groupApi;