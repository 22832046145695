import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {FaSearch} from 'react-icons/fa'
import DisplayQR from '../DisplayQR'

function TabCashdeskv1(props) {

    const {cashdesks, shopID, err, shop} = props;
    const [query, setQuery] = useState('');
    const [cashdesk, setCashdesk] = useState('');
    const navigate = useNavigate();

    const openInNewTab = (url, id) => {
      window.open(url, '_blank', 'noopener,noreferrer');
      setCashdesk(id);
      navigate("../../Cockpit")
    };
  
    const handleChange = event => {
      setQuery(event.target.value);
    };

    if (err!==null) {

      return(<div className='text-center py-3'>Veuillez sélectionner un magasin.</div>)
    }

    else if ((cashdesks==="" || cashdesks===null)) {

      return (
        <div>
          <div>'Loading...'</div>
        </div>

        )} 
    else if (cashdesk!==null && cashdesk!=='') {

      return(<DisplayQR cashdesk={cashdesk} shopID={shopID}/>)} 
    
    else {

      const data=cashdesks.data.data;

      return (
          <div>

            <div className="relative shadow-md sm:rounded-lg my-1">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <FaSearch size={16} color="grey"/>
                </div>
                <input 
                type="text" 
                id="table-search" 
                className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                placeholder="Search for items"
                onChange={handleChange}
                value={query}/>
            </div>
            {data.length===0 ?
            <div className='text-center py-3'>Le magasin {shop} n'a pas de caisse déclarée!</div> :
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg py-1">
              <div className="h-96">
              
                {data.filter(item => item.attributes.internal.toLowerCase().includes(query)).map((item, i) =>
                <div key={i} className="py-px px-1">
                  <div className="block bg-white py-px border-t rounded-md hover:drop-shadow-lg">
                    <div className="px-4 py-1 flex justify-between">
                      <span className="text-sm font-semibold text-gray-900">{item.attributes.internal}</span>
                      <span className="text-sm font-semibold text-gray-600">
                      <button 
                        onClick={()=>{openInNewTab("/:"+item.id, item.id)}}
                        className='text-[white] bg-sky-500 w-[112px] py-1 rounded-md font-medium text-md hover:drop-shadow-md'>
                          Show QUIPO interface</button>
                      </span>
                    </div>
                    <span className="text-sm text-gray-900 px-4 py-1">{item.id}</span>
                    <p className="px-4 py-1 text-sm text-gray-700">{item.attributes.b2b}</p>
                    <p className="px-4 py-1 text-sm text-gray-700">{item.attributes.title}</p>
                  </div>
                </div>
                )}
              </div>
            </div>
            }
          </div>
        )
      }
  }

export default TabCashdeskv1