import React, {useState} from 'react'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import Dashboard from './cockpit/Dashboard'
import Users from './cockpit/Users'
import Groups from './cockpit/Groups'
import Contracts from './cockpit/Contracts'
import DataRecap from './cockpit/DataRecap'

import QRGeneration from './features-v1/QRGeneration'

function Cockpit() {
  const [navHam, setNavHam] = useState(true);
  const [go, setGo] = useState('Dashboard');

  const goDashboard = () => {
    setGo('Dashboard');
  };

  const goUsers = () => {
    setGo('Users');
  };

  const goGroups = () => {
    setGo('Groups');
  };

  const goContracts = () => {
    setGo('Contracts');
  };

  const handleNavHam = () => {
    setNavHam(!navHam);
  };

  return (
    
    <div className='flex w-full'>
          <QRGeneration/>
          {/* DESACTIVé POUR DEVELOPPER LE B2B V1
          
            {navHam? 
            <div className="flex w-60 h-full flex-col space-y-2 border-r-2 justify-between border-gray-200 bg-white p-2 divide-y divide-solid divide-blue-400"> 

              <div>
                <p className='font-medium text-xl text-center py-4'> Espace B2B </p>
                  
                <a href="#" className="flex items-center text-blue-900 space-x-1 rounded-md px-2 py-3 hover:bg-gray-100 hover:text-blue-600"
                  onClick={goDashboard}>
                    <span>Dashboard</span>
                </a>

                <a href="#" className="flex items-center text-blue-900 space-x-1 rounded-md px-2 py-3 hover:bg-gray-100 hover:text-blue-600"
                onClick={goUsers}>
                    <span>Users</span>
                </a>

                <a href="#" className="flex items-center text-blue-900 space-x-1 rounded-md px-2 py-3 hover:bg-gray-100 hover:text-blue-600"
                onClick={goGroups}>
                    <span>Groups</span>
                </a>

                <a href="#" className="flex items-center text-blue-900 space-x-1 rounded-md px-2 py-3 hover:bg-gray-100 hover:text-blue-600"
                onClick={goContracts}>
                    <span>Contracts</span>
                </a>
              </div>

              <button className="flex flex-row justify-end items-center py-5" onClick={() => handleNavHam()}>
                <div className='flex item-center text-sm font-light px-2 py-1 text-blue-600 underline'>
                  <span>Collapse</span>
                </div>
                <div>
                  {navHam? <FaChevronLeft size={28}/> : <FaChevronRight size={28}/>}
                </div>
              </button>

            </div>
            :
            <div className="flex w-15 h-full flex-row space-y-2 border-r-2 border-gray-200 bg-white p-2 divide-y divide-solid divide-blue-400"> 

              <button className="flex flex-row justify-end items-end py-5" onClick={() => handleNavHam()}>
                <div>
                  {navHam? <FaChevronLeft size={28}/> : <FaChevronRight size={28}/>}
                </div>
              </button>

            </div>}
          </div>

          </div>
          <div className='flex flex-col basis-full'>
            <div className="hero-headline flex flex-col items-center justify-center pt-4 text-center">
                <h1 className=" font-bold text-3xl text-gray-900">Cockpit</h1>
                <p className=" font-base text-base text-gray-600">Manage users, roles, brands & shops</p>
            </div>

            <DataRecap/>

            {go==="Dashboard" ? <Dashboard/>:null}
            {go==="Users" ? <Users />:null}
            {go==="Groups" ? <Groups/>:null}
            {go==="Contracts" ? <Contracts/>:null}
          */}
    </div>




  )
}

export default Cockpit