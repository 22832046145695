import React from 'react';
import {useState, useEffect} from 'react';

import {useLazyEditShopQuery} from "../../../../services/modules/store";
import {useLazyEditMessageQuery, useLazyGetMessagesQuery, useLazyDeleteMessagesQuery} from "../../../../services/modules/ticketmessage";

import {Buffer} from 'buffer';
import {useLauncherContext} from "../../../features-v1/qrGenerationContext";

function EditShop(props) {
    const context = useLauncherContext()

    // context.fetchStore => true / false
    // context.onFetchStore(true)
    const {shop, setModal, backEdit, fTriggerUpdate} = props;

    const [editShop, shopEdited] = useLazyEditShopQuery();
    const [editMessage, message] = useLazyEditMessageQuery();
    const [getMessages, messages] = useLazyGetMessagesQuery();
    const [deleteMessages, eraseMess] = useLazyDeleteMessagesQuery();

    const [errEdit, setErrEdit] = useState('');
    const [errEditMess, setErrEditMess] = useState('');
    const [errGetMess, setErrGetMess] = useState('');
    const [errDel, setErrDel] = useState('');

    const [editTitle, setEditTitle] = useState(shop.title);
    const [editDescription, setEditDescription] = useState(shop.description);
    const [editMessageTicket, setEditMessageTicket] = useState(shop.payment_label);
    const [editLogo, setEditLogo] = useState(shop.logo_main);
    const [editSiret, setEditSiret] = useState(shop.siret);
    const [editTVA, setEditTva] = useState(shop.tva_intra);
    const [editAdresse, setEditAdresse] = useState(shop.address_address);
    const [editContact, setEditContact] = useState(shop.phone_address);
    const [editFactuAdresse, setEditFactuAdresse] = useState(shop.address_billing);
    const [editFactu, setEditFactu] = useState(shop.phone_billing);
    

    
    const [widgetMessage, setWidgetMessage] = useState('');
    const [messagesList, setMessagesList] = useState('');

    function refreshPage() {
        //window.location.reload(false);
        fTriggerUpdate(true);
        setEditTitle(null);
        setEditDescription(null);
        setEditMessageTicket(null);
        setWidgetMessage(null);
        setMessagesList(null);
        fTriggerUpdate(false);
      }

    function cancelEdit () {
        setModal(true);
        backEdit(false);
    };

    const addWidget = async () => {
        const message = await editMessage({
            "data": {
                "type": "TICKETMESSAGE",
                "action": "CREATE",
                "attributes": {
                    "pid": shop.tsid,
                    "mtype": "WIDGET",
                    "data64": widgetMessage,
                }}});
                if (message.isError===true) {
                    setErrEditMess(message.error.data.code);
                    } else {
                    setErrEditMess(message.isSuccess);
                    };
    }

    const editStore = async () => {
        const rep = await editShop({
            "data": {
                "type": "STORE",
                "action": "MODIFY",
                "id": shop.tsid,
                "attributes": {
                    "title": editTitle,
                    "description": editDescription,
                    "payment_label": editMessageTicket,
                    "logo_main":editLogo,
                    "siret":editSiret,
                    "tva_intra":editTVA,
                    "address_address":editAdresse,
                    "phone_address":editContact,
                    "address_billing":editFactuAdresse,
                    "phone_billing":editFactu,
                }}});

        if (rep.isError) {
            setErrEdit(rep.error.data.code);
          } else {
            setErrEdit(rep.isSuccess);
            setErrEdit(null);
            setEditMessageTicket('')
            console.log('contextcontextcontext', context)
            context.onFetchStore(true)
          }
    };

    const deleteWidget = async (id) => {
        const rep = await deleteMessages(id);
        if (rep.isError===true) {
            setErrDel(rep.error.data.code);
          } else {
            setErrDel(rep.isSuccess);
            setErrDel(null);
          };
    }

    useEffect(() => {
        const fetchData = async () => {
          const rep = await getMessages({
            "data": {
                "type": "TICKETMESSAGE",
                "action": "SEARCH",
                "attributes": {
                    "pid": shop.tsid
                }
            },
          });
          if (rep.isError===true) {
            setErrGetMess(rep.error.data.code);
          } else {
            setMessagesList(rep.data.data);
            setErrGetMess(rep.isSuccess);
            setErrGetMess(null);
          };
        };

        fetchData()
        .catch(console.error);
    },[errEditMess, errDel, fTriggerUpdate, editTitle, errEdit, errGetMess]);

    function convertToUtf8 (message) {
        const buffone = Buffer.from(message, "base64");
        const str = buffone.toString("utf8");
        return str;
    }

    function convertToBase64 (message) {
        const bufftwo = Buffer.from(message, "utf8");
        const base64 = bufftwo.toString("base64");
        return base64;
    }

  return (

    <>
    <div className="modal">
        <div className="overlay">
            <div className="modal-content">
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">

                    <div className="mb-5">
                        <img src={"https://tl-cdn.occiback.dev/res/logo/"+shop.logo_main+".png"} alt="logo-shop"/>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-full px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-message">
                                Logo
                            </label>
                            <input
                                value={editLogo}
                                className="appearance-none block w-full bg-gray-100 text-start text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm mb-3" 
                                id="grid-message" 
                                type="text" 
                                placeholder={shop.logo_main}
                                onChange={ event => setEditLogo(event.target.value) }/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="grid-brand-name">
                                Nom de magasin
                            </label>
                            <input
                                value={editTitle}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-red rounded py-3 px-4 mb-3 text-sm" 
                                id="grid-brand-name" 
                                type="text" 
                                placeholder={shop.title}
                                onChange={ event => setEditTitle(event.target.value)}/>
                        </div>
                        <div className="md:w-1/2 px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-siret-name">
                                Siret
                            </label>
                            <input
                                value={editSiret}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm" 
                                id="grid-siret-name" 
                                type="text" 
                                placeholder={shop.siret}
                                onChange={ event => setEditSiret(event.target.value)}/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="grid-brand-name">
                                Numéro de TVA
                            </label>
                            <input
                                value={editTVA}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-red rounded py-3 px-4 mb-3 text-sm" 
                                id="grid-brand-name" 
                                type="text" 
                                placeholder={shop.tva_intra}
                                onChange={ event => setEditTva(event.target.value)}/>
                        </div>
                        <div className="md:w-1/2 px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-siret-name">
                                Adresse
                            </label>
                            <input
                                value={editAdresse}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm" 
                                id="grid-siret-name" 
                                type="text" 
                                placeholder={shop.address_address}
                                onChange={ event => setEditAdresse(event.target.value)}/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="grid-brand-name">
                                Contact
                            </label>
                            <input
                                value={editContact}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-red rounded py-3 px-4 mb-3 text-sm" 
                                id="grid-brand-name" 
                                type="text" 
                                placeholder={shop.phone_address}
                                onChange={ event => setEditContact(event.target.value)}/>
                        </div>
                        <div className="md:w-1/2 px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-siret-name">
                                Adresse de facturation
                            </label>
                            <input
                                value={editFactuAdresse}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm" 
                                id="grid-siret-name" 
                                type="text" 
                                placeholder={shop.address_billing}
                                onChange={ event => setEditFactuAdresse(event.target.value)}/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="grid-brand-name">
                                Phone billing
                            </label>
                            <input
                                value={editFactu}
                                className="appearance-none block w-full bg-gray-100 text-gray-900 border border-red rounded py-3 px-4 mb-3 text-sm" 
                                id="grid-brand-name" 
                                type="text" 
                                placeholder={shop.phone_billing}
                                onChange={ event => setEditFactu(event.target.value)}/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-full px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-message">
                                Description
                            </label>
                            <input
                                value={editDescription}
                                className="appearance-none block w-full bg-gray-100 text-start text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm mb-3" 
                                id="grid-message" 
                                type="text" 
                                placeholder={shop.description}
                                onChange={ event => setEditDescription(event.target.value) }/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-full px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-message">
                                Message ticket de caisse
                            </label>
                            <input
                                value={editMessageTicket}
                                className="appearance-none block w-full bg-gray-100 text-start text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm mb-3" 
                                id="grid-message" 
                                type="text" 
                                placeholder={shop.payment_label}
                                onChange={ event => setEditMessageTicket(event.target.value) }/>
                        </div>
                    </div>

                    <div className="-mx-3 md:flex mb-6">
                        <div className="md:w-full px-3">
                            <label 
                                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" 
                                htmlFor="grid-message">
                                Messages widget
                            </label>
                            <div>
                                <input 
                                    className="appearance-none block w-full bg-gray-100 text-start text-gray-900 border border-gray-300 rounded py-3 px-4 text-sm mb-3" 
                                    id="grid-message" 
                                    type="text" 
                                    placeholder={"Ajouter un message dans les widgets"}
                                    onChange={ event => setWidgetMessage(convertToBase64(event.target.value))}/>
                                <button
                                    class="ml-2 p-2 text-electric hover:text-white hover:bg-electric rounded-lg flex"
                                    onClick={() => addWidget()}
                                    >   
                                    <svg class="h-6 w-6"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg>
                                    <span>Ajouter un widget</span>
                                </button>
                            </div>

                            {messagesList!==null && messagesList!=='' ?
                                <div>
                                    {messagesList.map((item, i) => 
                                        <div 
                                            key={i} 
                                            className="flex justify-between w-full bg-white text-start text-gray-900 border border-gray-200 rounded py-3 px-4 text-sm mb-3" >
                                            <p className="flex p-2">{convertToUtf8(item.attributes.data64)}</p>
                                            <button 
                                                class="flex text-red-800 p-2"
                                                onClick={() => deleteWidget(item.id)}>
                                                <svg class="h-6 w-6 text-red-800"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
                                                <span>Remove</span>
                                            </button>
                                        </div>)}
                                </div> : null
                            }
                        </div>
                    </div>

                    <div className="md:w-full px-3">
                        <div className='flex flex-row'>
                            <label 
                                    className="uppercase w-40 tracking-wide text-gray-900 text-xs font-bold py-2" 
                                    htmlFor="grid-message">
                                    Identifiant B2B:
                                </label>
                            <p className='w-full tracking-wide text-gray-900 text-xs font-light py-2'>{shop.b2b}</p>
                        </div>
                        <div className='flex flex-row'>
                            <label 
                                    className="uppercase w-56 tracking-wide text-gray-900 text-xs font-bold py-2" 
                                    htmlFor="grid-message">
                                    Identifiant Marque:
                                </label>
                            <p className='w-full tracking-wide text-gray-900 text-xs font-light py-2'>{shop.toid}</p>
                        </div>
                        <div className='flex flex-row'>
                            <label 
                                    className="uppercase w-56 tracking-wide text-gray-900 text-xs font-bold py-2" 
                                    htmlFor="grid-message">
                                    Identifiant Magasin:
                                </label>
                            <p className='w-full tracking-wide text-gray-900 text-xs font-light py-2'>{shop.tsid}</p>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between md:w-full px-3 py-8">
                        <button
                        onClick={()=>cancelEdit(false)}
                        className='text-[white] bg-gray-400 w-[69px] py-1 rounded-md font-medium text-md hover:drop-shadow-md'
                        >Back</button>
                        <button
                        onClick={()=>{editStore()}}
                        className='text-[white] bg-orange-500 w-[69px] py-1 rounded-md font-medium text-md hover:drop-shadow-md'
                        >Update</button>
                    </div>

                </div>

            </div>
        </div>
    </div>        
</>
  )
}

export default EditShop