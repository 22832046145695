import React, {useState, useEffect} from 'react'
import {FaSearch} from 'react-icons/fa'
//import DisplayCashdesks from '../DisplayCashdesks'
import EditShop from "../../cockpit/broadsearch/editModals/EditShop"

function TabShopv1(props) {

    const {shops, fShopID, fEditShop, fTriggerUpdate, fShopName} = props;
    const [query, setQuery] = useState('');
    const [modal, setModal] = useState(false);
    const [shop, setShop] = useState('');

    const handleChange = event => {
      setQuery(event.target.value);
    };

    function editShop (shop) {
      setShop(shop);
      setModal(false);
      fEditShop(true);
      fShopName(shop.title);
    };

    useEffect(() => {
      if (shop !== '' && Array.isArray(shops.data.data)) {
        console.log('on passe dans la condition')
        console.log('on affiche le shop selected', shop)
        shops.data.data.forEach(shopItem => {
          console.log('on affiche un store', shopItem)
          if (shopItem.tsid ===  shop.tsid) {
            console.log('trouve bien le meme id')

            setShop(shopItem)
          }
        })
      }
    }, [shop, shops]);

    if (shops==="" || shops===null) {

      return (
        <div>
            <div>'Loading...'</div>
        </div>

        )}
    else if (shop!==null && shop!=='' && modal===false) {

      return(<EditShop shop={shop} setModal={(value) => setModal(value)} backEdit={(value) => fEditShop(value)} triggerUpdate={(value) => fTriggerUpdate(value)}/>)}
    else {
          const data=shops.data.data;

          return (
            <div>

              <div className="relative shadow-md sm:rounded-lg my-1">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <FaSearch size={16} color="grey"/>
                  </div>
                  <input
                  type="text"
                  id="table-search"
                  className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search for items"
                  onChange={handleChange}
                  value={query}/>
              </div>
              {data.length===0  ?
              <div className='text-center py-3'>Veuillez sélectionner un magasin.</div> :
              <div className="overflow-x-auto relative shadow-md sm:rounded-lg py-1">
                <div className="h-96">
                  {data.filter(item => item.attributes.title.toLowerCase().includes(query)).map((item, i) =>
                  <div key={i} onClick={() => fShopID(item.attributes.tsid)} className="py-px">
                    <div className="block bg-white py-px border-t rounded-md hover:drop-shadow-lg">
                      <div className="px-4 py-1 flex justify-between">
                        <span className="text-sm font-semibold text-gray-900">{item.attributes.title}</span>
                        <span className="text-sm font-semibold text-gray-600">
                        <button
                          onClick={()=>{editShop(item.attributes)}}
                          className='text-[white] bg-sky-500 w-[69px] py-1 rounded-md font-medium text-md hover:drop-shadow-md'>
                            Edit</button>
                        </span>
                      </div>
                      <span className="text-sm text-gray-900 px-4 py-1">{item.attributes.description}</span>
                      <p className="px-4 py-1 text-sm text-gray-700">{item.attributes.address_address}</p>
                      <p className="px-4 py-1 text-sm text-gray-700">{item.attributes.siret}</p>
                    </div>
                  </div>
                  )}
                </div>
              </div>
              }
            </div>
          )
        }
  }

export default TabShopv1