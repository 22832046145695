import React from 'react'
import {FaFacebookSquare,FaGithubSquare,FaInstagram,FaTwitterSquare} from 'react-icons/fa'
import logo from '../assets/img/logo-quipo-forest.png'

function Footr() {
  return (
    <nav className={`border-gray-200 px-2 sm:px-4 py-3 bg-darkness`}>
        <div className="max-w-[1240px] mx-auto py-4 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
            <div className="flex flex-row justify-between">
                <a href="http://auth.occidev31.com:3000/" className="flex items-center">
                    <img className="object-contain h-16" src={logo} alt="Logo" />
                </a>
                <div className='flex justify-end px-5 my-6'>
                    <FaFacebookSquare size={30}/>
                    <FaInstagram size={30}/>
                    <FaTwitterSquare size={30}/>
                    <FaGithubSquare size={30}/>
                </div>
            </div>
            <div className='lg:col-span-2 flex justify-between mt-6'>
              {/*   <div>
                    <h6 className='font-medium text-gray-400'>Menu</h6>
                    <ul>
                        <li className='py-2 text-sm'>Accueil</li>
                        <li className='py-2 text-sm'>S'identifier</li>
                        <li className='py-2 text-sm'>Contact</li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-medium text-gray-400'>Support</h6>
                    <ul>
                        <li className='py-2 text-sm'>Pricing</li>
                        <li className='py-2 text-sm'>Guides</li>
                        <li className='py-2 text-sm'>API</li>
                        <li className='py-2 text-sm'>Discord</li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-medium text-gray-400'>Company</h6>
                    <ul>
                        <li className='py-2 text-sm'>About</li>
                        <li className='py-2 text-sm'>Blog</li>
                        <li className='py-2 text-sm'>Jobs</li>
                        <li className='py-2 text-sm'>Press</li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-medium text-gray-400'>Legal</h6>
                    <ul>
                        <li className='py-2 text-sm'>Claim</li>
                        <li className='py-2 text-sm'>Policy</li>
                        <li className='py-2 text-sm'>Terms</li>
                    </ul>
                </div> */}
            </div>
        </div>
    </nav>
  )
}

export default Footr