import {useState, useEffect} from 'react'
import {useLazySignUpQuery, useLazySignInQuery} from "../services/modules/auth";
import {onChangeHaveSignIn, userEmail} from "../store/user";
import {useNavigate} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';


function SignIn() {

const dispatch = useDispatch()
const navigate = useNavigate();
  
const [email, setEmail] = useState('');
const [userData, setUserData] = useState('');
const [password, setPassword] = useState('');
const [signUp, signUpR] = useLazySignUpQuery();
const [signIn, signInR] = useLazySignInQuery();
const [codeErrSignIn, setCodeErrSignIn] = useState(null);
const [isAdmin, setIsAdmin] = useState('true'); // A utiliser dans l'avenir pour activer/désactiver 
                                                // le bouton de suppression d'un utilisateur.
                                                // Activé par défaut pour le développement
const [token, setToken] = useState();
const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
         
const handleSubmitSignIn = async (event) => {
    event.preventDefault();
    const rep = await signIn({
      "data": {
        "type": "AUTH",
        "action": "SIGNIN_EMAIL",
        "attributes": {
          "email": email,
          "password": password,
          "captcha": token
        }
      }
    });
    if (rep.isError===true) {
      setCodeErrSignIn(rep.error.data.code);
    } else {
      setUserData(rep.originalArgs.data.attributes.email);
      dispatch(userEmail({ userInfo: rep.originalArgs.data.attributes.email}));
    };
  }


  const handleSubmitDeleteUser = (event) => {
    event.preventDefault();
    signUp({
      "data": {
        "type": "AUTH",
        "action": "DELETE",
        "attributes": {
          "email": email,
        }
      }
    });
  }

  const navigateToSignUp = () => {
    // 👇️ navigate to /SignUp
    navigate('/SignUp');
  };
  console.log(token);

  useEffect(() => {
    if (signInR.isSuccess) {
      dispatch(onChangeHaveSignIn({ haveSignIn: true }));
      navigate('../Cockpit')
    }
  }, [signInR]);

  return (

      <div className=" flex flex-row bg-gray-100 py-6">
        <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-white px-6 py-6 rounded shadow-md text-black w-full">
            <h1 className="mb-8 text-3xl text-center uppercase">S'identifier</h1>
            <input 
                type="text"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="email"
                placeholder="Email"
                value={email} 
                onChange={event => setEmail(event.target.value)} />

            <input 
                type="password"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="password"
                placeholder="Password"
                value={password} 
                onChange={event => setPassword(event.target.value)} />
            <GoogleReCaptcha         
                    onVerify={(token) => {setToken(token)}}
                    refreshReCaptcha={refreshReCaptcha}/>
            <button
                type="submit"
                className={`w-full text-center py-3 rounded bg-electric text-white hover:bg-green-dark focus:outline-none my-1`}
                onClick={handleSubmitSignIn}
                >Sign in</button>

            {codeErrSignIn!=null ? 
              <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
              <span className="font-medium">Info:</span> {codeErrSignIn}
            </div>:null}

            {!isAdmin ? <button
                type="submit"
                className="w-full text-center py-3 rounded bg-red-800 text-white hover:bg-green-dark focus:outline-none my-1"
                onClick={handleSubmitDeleteUser}
                >Supprimer l'utilisateur</button> :null}

          </div>

          <p className='text-center md:text-xl text-xl font-bold text-gray-800 py-6'>Pas encore de compte?</p>
          <button 
          onClick={navigateToSignUp}
          className={`text-[white] bg-electric w-[200px] rounded-md font-medium mx-auto py-2 text-xl`}>Créer un compte</button>
      
        </div>

      </div>
  )
}

export default SignIn