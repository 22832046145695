import React from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import colors from '../utils/colors';
import visual from '../assets/img/quipo-visual.png'

function Hero() {

  const navigate = useNavigate();

  const navigateToSignUp = () => {
    // 👇️ navigate to /contacts
    navigate('/SignUp');
  };

  return (
    <div className='text-black'>
      <div className='max-w-[800px] w-full mx-auto text-center flex flex-col justify-center'>
        <p className={`uppercase font-bold p-2`}>Le numérique vraiment à votre service</p>
        <h1 className={`text-[${colors.darkness}] md:text-5xl sm:text-4xl text-2xl font-bold md:py-6`}>CIAO LES TICKETS PAPIER!</h1>
        <div>
          <div className="flex justify-center">
            <img src={visual} className="h-80"/>
          </div>
          <p className='md:text-3xl sm:text-2xl text-xl font-bold py-4'>
          <TypeAnimation
            sequence={[
              'Une solution maline qui sauve des arbres',
              1000,
              "Une solution maline qui économise de l'eau",
              1000,
              'Une solution maline sans substances nocives',
              1000,
              'Une solution maline qui réduit les déchets',
              1000,
              'Une solution maline qui économise du pétrole',
              1000,
              'Une solution maline qui limite les émissions de GES',
              1000,
              () => {
                // Place optional callbacks anywhere in the array
              }
            ]}
            wrapper="div"
            cursor={true}
            repeat={Infinity}
          />
          </p>
        </div>
        <button 
          onClick={navigateToSignUp}
          className={`text-[white] bg-electric w-[125px] rounded-md font-medium my-4 mx-auto py-2 text-xl`}>Allons-y!</button>
      </div>
    </div>
  )
}

export default Hero