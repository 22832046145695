import { api } from "../../api";
import signUp from "./signUp";
import signIn from "./signIn";
import link from "./link";
import logout from "./logout";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signUp: signUp(build),
    signIn: signIn(build),
    link: link(build),
    logout: logout(build),
  }),
  overrideExisting: false,
});

export const { useLazySignUpQuery, useLazySignInQuery, useLazyLinkQuery, useLazyLogoutQuery} =
  authApi;
