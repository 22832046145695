import { api } from "../../api";
import getUsers from "./getUsers";
import getUsersFromGroup from "./getUsersFromGroup";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: getUsers(build),
    getUsersFromGroup: getUsersFromGroup(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetUsersQuery, 
  useLazyGetUsersFromGroupQuery } = authApi;