import React from 'react'
import {useState, useEffect} from 'react'
import {useLazyLogoutQuery} from "../services/modules/auth";
import {useLazyGetUsersQuery} from "../services/modules/access";
import {onChangeHaveSignIn, userEmail, userB2bID} from "../store/user";
import {useNavigate} from 'react-router-dom'
import {useDispatch} from "react-redux";

function User(props) {

    const {userID, userMail} = props;

    const dispatch = useDispatch()
    const navigate = useNavigate();
          
    const [logout, logoutR] = useLazyLogoutQuery();
    const [codeErrSignIn, setCodeErrSignIn] = useState(null);
    const [getUsers, users] = useLazyGetUsersQuery();
    const [userGroups, setUserGroups] = useState('');
    const [errGet, setCodeErrGet] = useState('');

    const handleLogout = async (event) => {
        event.preventDefault();
        const rep = await logout({});

        if (rep.isError==true) {
            setCodeErrSignIn(rep.error.data.code);
        } else {
            dispatch(onChangeHaveSignIn({ haveSignIn: false }));
            //dispatch(userEmail({ userEmail: null}));
            //dispatch(userB2bID({ userB2bID: null}));
            navigate('../Home');
        };
        };

    const handleRole = (item) => {
        console.log(item);
    };

    useEffect(() => {
        if (userID !== '') {
            const fetchData = async () => {
                const rep = await getUsers({
                    "data": {
                    "type": "COGNITO_USER",
                    "action": "GET",
                    }
                    });
                    if (rep.isError===true) {
                        setCodeErrGet(rep.error.data.code);
                    } else { 
                        setUserGroups(rep.data.data.attributes.groups);
                    };
                };

            fetchData()
            .catch(console.error);
        };
    }, [userID]);

    if (userID==='') {
        <div>Loading...</div>
    } else {
        return (
                <div className="shadow-lg border-gray-200 rounded-2xl bg-gray-800 p-4">
                    <div className="flex-row gap-4 flex justify-center items-center">
                        <div className=" flex flex-col">
                            <span className="text-gray-400 text-lg font-medium">
                                {userMail}
                            </span>
                            <span>
                                {userGroups!=='' ? userGroups.map((item)=>
                                    <button 
                                    className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-300 bg-slate-700 last:mr-0 mr-1 hover:text-white"
                                    onClick={() => handleRole(item)}
                                    > {item} </button>):null}
                            </span>
                        </div>
                        <span className="text-gray-400 text-xs">
                            <button 
                            type="button" 
                            className="text-gray-200 hover:text-white border border-gray-600 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1 text-center"
                            onClick={handleLogout}
                            >Logout</button>
                        </span>
                    </div>
                </div>
        )
    }
}

export default User