import { api } from "../../api";
import getCashdesks from "./getCashdesks";

export const cashdeskApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCashdesks: getCashdesks(build),
  }),
  overrideExisting: false,
});

export const {useLazyGetCashdesksQuery} = cashdeskApi;