import React from 'react'
import TabUsers from './broadsearch/tables/TabUsers'

function Users() {

    return (
    <div >
      <TabUsers/>
    </div>
    )
}

export default Users