import {createContext, useContext} from "react";

export const launcherContext = createContext({
  fetchStore: true,
  onFetchStore: (value) => {}
});

export function useLauncherContext() {
  return useContext(launcherContext);
}
