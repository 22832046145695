import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: { haveSignIn: false , userEmail: null, b2bID: null},
  reducers: {
    onChangeHaveSignIn: (state, { payload: { haveSignIn} }) => {
      if (typeof haveSignIn !== "undefined") {
        state.haveSignIn = haveSignIn;
      }
    },
    userEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    userB2bID: (state, action) => {
      state.b2bID = action.payload;
    },
  },
});

export const {onChangeHaveSignIn, userEmail, userB2bID} = slice.actions;
export default slice.reducer;
