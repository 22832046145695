import React from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate
} from "react-router-dom";
import {useSelector} from "react-redux";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Cockpit from "./components/Cockpit";
import Footr from "./components/Footr";
import DisplayQR from "./components/features-v1/DisplayQR";

function RequireAuth({ children, auth }) {
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/signIn" state={{ from: location }} replace />;
  }
  return children;
}

function App() {
  const auth = useSelector((state) => state.user.haveSignIn);
  //const cashdesk = useSelector((state) => state.cashdesk.id);
  //<Route path="/:cashdeskId" element={<DisplayQR/>}/>

  return (
    <>

      <BrowserRouter>
        <Navbar/>
          <Routes>
            {auth ? 
              null : 
              <Route path="/" element={<Hero/>}/>
              }
            <Route exact path="/SignIn" element={<SignIn />} />
            <Route exact path="/SignUp" element={<SignUp />} />

            <Route exact path="/Cockpit" element={
                <RequireAuth auth={auth}>
                  <Cockpit/>
                </RequireAuth>
              }
            />

            <Route path={"/:cashdeskId"} element={
                <RequireAuth auth={auth}>
                  <DisplayQR/>
                </RequireAuth>
              }
            />

            </Routes>
      </BrowserRouter>

    <Footr/>
    </>
  );
}

export default App;
